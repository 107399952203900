import { configureStore } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
  currentMonth: dayjs().startOf('month'),
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = configureStore({
  reducer: {
    ui: changeState,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['set'],
        // Ignore these field paths in all actions
        // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['currentMonth', 'ui.currentMonth'],
      },
    }),
})

export default store
